import React, {useState} from 'react';
import styles from './index.module.scss';
import {DatePicker, Form, Toast} from "antd-mobile";
import moment from "moment/moment";
import {FormInstance} from "antd-mobile/es/components/form";
import {VisitorSaveRequest} from "../../../../../api/apply";

type PropsType = {
    form: FormInstance
}

type RangeType = { min: Date, max: Date }


function Index({form}: PropsType) {
    const [showStartDate, setShowStartDate] = useState<boolean>(false)
    const [showEndDate, setShowEndDate] = useState<boolean>(false)
    const [range, setRange] = useState<RangeType>()

    const showDatePicker = (field: string) => {
        let values: VisitorSaveRequest = form.getFieldsValue();
        if (field === 'startDate') {
            setRange({
                min: moment().toDate(),
                max: moment().add(10, 'days').toDate()
            })
            setShowStartDate(true)
        } else {
            let startDate = values.startDateItem ? moment(values.startDateItem) : undefined
            if (!startDate) {
                Toast.show('请先选择开始时间')
                return
            }
            setRange({
                min: startDate.toDate(),
                max: startDate.add(6, 'days').toDate()
            })
            setShowEndDate(true)
        }
    }
    const refreshEndDate = () => {
        let values: VisitorSaveRequest = form.getFieldsValue();
        let startDate = values.startDateItem ? moment(values.startDateItem) : undefined
        let endDate = values.endDateItem ? moment(values.endDateItem) : undefined
        if (startDate && endDate) {
            form.resetFields(['endDateItem'])
        }
    }

    return (
        <div className={styles.Main}>
            <Form.Item name='startDateItem' label='开始时间' trigger='onConfirm' onClick={() => showDatePicker('startDate')}
                       rules={[{required: true}]}>
                <DatePicker
                    title='开始时间选择'
                    visible={showStartDate}
                    onClose={() => setShowStartDate(false)}
                    min={range?.min}
                    max={range?.max}
                    onConfirm={refreshEndDate}
                >
                    {value =>
                        value ? moment(value).format('YYYY-MM-DD') : <p style={{color: '#ccc'}}>{'请选择开始时间'}</p>
                    }
                </DatePicker>
            </Form.Item>
            <Form.Item name='endDateItem' label='结束时间' trigger='onConfirm' onClick={() => showDatePicker('endDate')}
                       rules={[{required: true}]}>
                <DatePicker
                    title='结束时间选择'
                    visible={showEndDate}
                    onClose={() => setShowEndDate(false)}
                    min={range?.min}
                    max={range?.max}
                >
                    {value =>
                        value ? moment(value).format('YYYY-MM-DD') : <p style={{color: '#ccc'}}>{'请选择结束时间'}</p>
                    }
                </DatePicker>
            </Form.Item>
        </div>
    );
}

export default Index;
