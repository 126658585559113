import React, {ReactElement, useEffect, useState} from 'react';
import styles from './index.module.scss';
import {DownOutline,} from 'antd-mobile-icons'
import {approvalApi, ApplyQuery, ApplyResponse} from "../../../../../api/apply";
import {PageResponse} from "../../../../../common/DataType";
import {InfiniteScroll, Picker, PullToRefresh} from 'antd-mobile';
import moment from "moment/moment";
import StrUtil from "../../../../../util/StrUtil";
import {useNavigate} from "react-router-dom";
import {FullSelection} from "@icon-park/react";
import SummaryItem from "../../../../../common/SummaryItem";

type PropsType = {
    type: 1 | 2
}
const now = new Date()

function Index({type}: PropsType) {
    const navigate = useNavigate()
    const [approvalResponses, setApprovalResponses] = useState<ApplyResponse[]>([])
    const [query, setQuery] = useState<ApplyQuery>({approvalNode: {type: type}})
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [statusShow, setStatusShow] = useState<boolean>(false)
    const [timeShow, setTimeShow] = useState<boolean>(false)

    useEffect(() => {
    }, [])

    async function loadMore() {
        const current = Math.floor(approvalResponses.length / 5) + 1
        let axiosResponse = await approvalApi.pageMyApproval(query, {current: current, size: 5});
        let data: PageResponse<ApplyResponse> = axiosResponse.data.body;
        const newApprovalResponses = [...approvalResponses, ...data.list];
        await setApprovalResponses(newApprovalResponses)
        await setHasMore(current < data.pages)
        // console.log('page: ', current)
    }

    async function loadFirst() {
        await setApprovalResponses([])
        await setHasMore(true)
    }

    const timeOptions: any[] = [
        {
            label: '全部',
            value: ''
        }, {
            label: '近七日',
            value: moment(now).subtract(7, 'days').format('YYYY-MM-DD\T00:00:00') + '$' + moment(now).format('YYYY-MM-DD\T23:59:59')
        }, {
            label: '近30日',
            value: moment(now).subtract(30, 'days').format('YYYY-MM-DD\T00:00:00') + '$' + moment(now).format('YYYY-MM-DD\T23:59:59')
        },
    ]

    const statusOptions: any[] = [
        {
            label: '全部',
            value: ''
        }, {
            label: '审批中',
            value: '0'
        }, {
            label: '已同意',
            value: '1'
        }, {
            label: '已驳回',
            value: '2'
        }, {
            label: '已撤回',
            value: '3'
        }
    ]

    const setStatus = async (value: string) => {
        if (StrUtil.isEmpty(value)) {
            query.status = undefined
        } else {
            query.status = parseInt(value)
        }
        await setQuery({
            ...query
        })
        await loadFirst()
    }

    const setTime = async (value: string) => {
        if (StrUtil.isEmpty(value)) {
            query.createTimeMin = ''
            query.createTimeMax = ''
        } else {
            let items = value.split("$");
            query.createTimeMin = items[0]
            query.createTimeMax = items[1]
        }
        await setQuery({
            ...query
        })
        await loadFirst()
    }

    let statusSelectEl: ReactElement | undefined = <div className={styles.QueryPanelItem}
                                                        onClick={() => setStatusShow(true)}>
        <span>审批状态</span><DownOutline/></div>
    if (query.status) {
        let statusOption = statusOptions.find(e => e.value === (query.status + ''));
        statusSelectEl =
            <div className={styles.QueryPanelItem} style={{color: '#3e8eff'}} onClick={() => setStatusShow(true)}>
                <span>{statusOption.label}</span><DownOutline/></div>
    }
    let batchProcessEl = undefined
    if (type === 1) {
        statusSelectEl = undefined
        batchProcessEl = <>
            <div style={{width: 1, height: 12, backgroundColor: 'black', opacity: 0.1}}></div>
            <div className={styles.QueryPanelItem}>
                <FullSelection theme="outline" size="14" fill="#333"/>
                <span onClick={() => navigate('/main/todo/batchProcess')}>批量处理</span>
            </div>
        </>
    }


    let timeSelectEl = <div className={styles.QueryPanelItem} onClick={() => setTimeShow(true)}>
        <span>提交日期</span><DownOutline/></div>
    if (!StrUtil.isEmpty(query.createTimeMin) && !StrUtil.isEmpty(query.createTimeMax)) {
        const createTimeMin = moment(query.createTimeMin);
        const creatTimeMax = moment(query.createTimeMax);
        let startDateStr = createTimeMin.format('YYYY/MM/DD')
        let endDateStr = creatTimeMax.format('YYYY/MM/DD')
        if (createTimeMin.year() === creatTimeMax.year()) {
            startDateStr = createTimeMin.format('MM/DD')
            endDateStr = creatTimeMax.format('MM/DD')
        }
        timeSelectEl =
            <div className={styles.QueryPanelItem} style={{color: '#3e8eff'}} onClick={() => setTimeShow(true)}>
                <span>{startDateStr}-{endDateStr}</span><DownOutline/></div>
    }
    let contentEl = <div className={styles.Empty}>
        <img className={styles.EmptyImg} src={require('../../../../../assets/images/approval/empty.png')}/>
        <span className={styles.EmptyTip}>{type === 1 ? '暂无待处理' : '暂无已处理'}</span>
    </div>
    if (approvalResponses.length > 0) {
        contentEl = <>
            {approvalResponses.map((item, index) => <SummaryItem key={index} data={item}/>)}
        </>
    }
    return (
        <PullToRefresh onRefresh={async () => {
            await loadFirst()
        }}>
            <div className={styles.Main}>
                <div className={styles.QueryPanel}>
                    <Picker
                        columns={[statusOptions]}
                        visible={statusShow}
                        onClose={() => {
                            setStatusShow(false)
                        }}
                        onConfirm={value => setStatus(value[0] ? value[0] : '')}
                    />

                    {statusSelectEl}
                    <Picker
                        columns={[timeOptions]}
                        visible={timeShow}
                        onClose={() => {
                            setTimeShow(false)
                        }}
                        onConfirm={value => setTime(value[0] ? value[0] : '')}
                    />
                    {timeSelectEl}
                    {batchProcessEl}
                </div>
                {contentEl}
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}/>
            </div>
        </PullToRefresh>
    );
}

export default Index;
