class ArrUtil {

    public static isEmpty = (strs: any[]) => {
        if (!strs || strs.length === 0) {
            return true
        }
        return false
    }
}

export default ArrUtil