import React from 'react';
import styles from './index.module.scss';
import ProcessItem from "../ToDo/ProcessItem";


function Index() {

    return (
        <div className={styles.Main}>
            <ProcessItem type={2} />
        </div>
    );
}

export default Index;
