import {IBaseResponse} from "../common/DataType";
import request from "../util/request";

export type LoginInfo = {
    name: string //姓名
    username: string //账号：学工号或者管理员的账号
    type: string //账号类型

}

export type JwtToken = {
    expire: number //token过期时间（秒）
    token: string //令牌
}

export const tokenApi = {
    /**
     * 获取当前登录信息
     */
    getLoginInfo: () => {
        return request.get<IBaseResponse<LoginInfo>>("/auth/token/user/online/loginInfo");
    },
    /**
     * 渝快办获取token
     */
    getTokenYkb: (params: {code: string}) => {
        return request.get<IBaseResponse<JwtToken>>("/auth/token/user/ykb", {params});
    },

}
