import React, {ReactElement} from 'react';
import styles from './index.module.scss';
import {Collapse, Tag} from "antd-mobile";
import Approver from "../../Approver";
import {Right} from "@icon-park/react";
import {ApprovalNodeResponse} from "../../../api/apply";

type PropsType = {
    status: number
    approvalNodes: ApprovalNodeResponse[]
    open?: boolean
}

export const status2tag = new Map<number, ReactElement>()
status2tag.set(0, <Tag className={styles.Tag} color='#87d068'>审批中</Tag>)
status2tag.set(1, <Tag className={styles.Tag} color='success'>已通过</Tag>)
status2tag.set(2, <Tag className={styles.Tag} color='warning'>已驳回</Tag>)
status2tag.set(3, <Tag className={styles.Tag} color='default'>已撤回</Tag>)

function Index({status, approvalNodes, open}: PropsType) {
    if (!approvalNodes || approvalNodes.length === 0) {
        return <div/>
    }
    let tagEl = status2tag.get(status)
    let defaultActiveKey = '0'
    if (open) {
        defaultActiveKey = '1'
    }
    return (
        <div className={styles.Main}>
            <Collapse defaultActiveKey={[defaultActiveKey]} >
                <Collapse.Panel className={styles.Process} arrow={false} key='1' title={<div className={styles.First}><p>审批进度
                </p>{tagEl}<Right theme="outline" style={{marginLeft: 'auto'}} size="22" fill="#333"
                                  strokeWidth={1} strokeLinecap="square"/></div>}>
                    {approvalNodes.map((item, index) => <Approver key={index} last={index === approvalNodes.length - 1} data={item}/>)}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
}

export default Index;
