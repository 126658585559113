import React from 'react';
import styles from './index.module.scss';
import {Divider} from "antd-mobile";
import ItemImage from "../../../../../common/items/ItemImage";
import StrUtil from "../../../../../util/StrUtil";
import ItemCenter from "../../../../../common/items/ItemCenter";
import {ApplyDetailResponse} from "../../../../../api/applyDetail";

type PropsType = {
    details: ApplyDetailResponse[]
}

function Index({details}: PropsType) {

    return (
        <div className={styles.Main}>
            <Divider/>
            {details.map((item, index) => {
                return <div key={index}>
                    <div className={styles.Header}>
                        <div className={styles.Icon}>
                            <span>{index + 1}</span>
                        </div>
                        <div className={styles.ItemLeft}>
                            <p>访客{index + 1}</p>
                        </div>
                    </div>
                    <div className={styles.Content}>
                        <ItemCenter label={'姓名'} value={item.name}/>
                        <ItemCenter label={'电话'} value={item.phone}/>
                        <ItemCenter label={'证件类型'} value={item.idType}/>
                        <ItemCenter label={'证件号'} value={item.identityCardNumber}/>
                        <ItemCenter label={'性别'} value={item.gender}/>
                        <ItemCenter label={'单位'} value={item.company}/>
                        <ItemCenter label={'健康状态'} value={item.healthStatus}/>
                        <ItemCenter label={'车牌号'} value={item.plateNumber}/>
                        {StrUtil.isEmpty(item.attachment) ? undefined :
                            <ItemImage label={'附件'} urls={item.attachment.split(",")}/>}
                        {StrUtil.isEmpty(item.failedReason) ? undefined :
                            <ItemCenter style={{color: 'red'}} label={"申请失败"} value={item.failedReason}/>}
                    </div>
                    <Divider/>
                </div>
            })}

        </div>
    );
}

export default Index;
