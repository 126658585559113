import {IBaseResponse} from "../common/DataType";
import request from "../util/request";

export type TicketVisitorDetail = {
    startDate: string //起始日期
    endDate: string //截止日期
    name: string //姓名
    phone: string //电话
    idType: string //证件类型
    identityCardNumber: string //证件号
    gender: string //性别
    plateNumber: string //车牌号
    company: string //单位
    attachment: string //附件（逗号分隔的文件链接）
    newest: boolean //true: 最新的， false：不是最新的
    status: number //推送状态,1: 成功， 2：失败

}

export const ticketApi = {
    /**
     * 获取访客申请记录数据详情
     */
    approvalDetail: (params: { ticketEncryptStr: string, id: string }) => {
        return request.get<IBaseResponse<TicketVisitorDetail>>("/ticket/approval/detail", {params});
    }
}
