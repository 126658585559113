import React from 'react';
import styles from './index.module.scss';

type PropsType = {
    label: string
    value: string
}

function Index({label, value}: PropsType) {
    return (
        <div className={styles.Main}>
            <p className={styles.Label}>{label}</p>
            <p className={styles.Value}>{value}</p>
        </div>
    );
}

export default Index;
