import request from "../util/request";
import {IBaseResponse} from "../common/DataType";


export const fileApi = {
    /**
     * 上传文件
     */
    uploadFile: (data: FormData) => {
        return request.post<IBaseResponse<string>>("/mapi/file/upload", data);
    }
}
