import React from 'react';
import styles from './index.module.scss';
import {useNavigate} from "react-router-dom";


function Index() {
    const navigate = useNavigate();
    return (
        <div className={styles.Main}>
            <div className={styles.First}>
                <img src={require('../../assets/images/success/success.png')} width={180} height={180}/>
            </div>
            <div className={styles.Second}>
                <div>提交成功</div>
                <div>等审批中结果</div>
            </div>
            <div className={styles.Bottom}>
                <div className={styles.Left} onClick={() => navigate("/main/home", {replace: true})}>返回首页</div>
                <div className={styles.Right} onClick={() => navigate("/main/record", {replace: true})}>申请记录</div>
            </div>

        </div>
    );
}

export default Index;
