import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {HashRouter, Route, Routes} from "react-router-dom";
import Root from "./pages/Root";
import Login from "./pages/Login";
import Wework from "./pages/Login/Wework";
import Cas from "./pages/Login/Cas";
import Main from "./pages/Main";
import Home from "./pages/Main/Home";
import Notice from "./pages/Main/Notice";
import Success from "./common/Success";
import Apply from "./pages/Main/Apply";
import RecordDetail from "./pages/Main/Record/RecordDetail";
import Record from "./pages/Main/Record";
import Wechat from "./pages/Login/Wechat";
import Admission from "./pages/Main/Admission";
import Env from "./pages/Env";
import Approval from "./pages/Main/Approval";
import BatchProcess from "./pages/Main/Approval/ToDo/BatchProcess";
import Detail from "./pages/Main/Approval/Detail";


export const routeMenusMap = new Map()
routeMenusMap.set("/login", {name: '登录'})
routeMenusMap.set("/login/wechat", {name: '微信'})
routeMenusMap.set("/login/wework", {name: '企业微信'})
routeMenusMap.set("/login/cas", {name: '统一身份认证'})
routeMenusMap.set("/main/approvalCenter", {name: '审批'})
routeMenusMap.set("/main/success", {name: '提交结果'})
routeMenusMap.set("/main/notice", {name: '进校须知'})
routeMenusMap.set("/main/home", {name: '访客系统'})
routeMenusMap.set("/main/apply", {name: '访客申请'})
routeMenusMap.set("/main/record", {name: '申请记录'})
routeMenusMap.set("/main/recordDetail", {name: '记录详情'})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <HashRouter>
            <Routes>
                <Route path="/" element={<Root/>}>
                    <Route path="login" element={<Login/>}/>
                    <Route path="login/wechat" element={<Wechat/>}/>
                    <Route path="login/wework" element={<Wework/>}/>
                    <Route path="login/cas" element={<Cas/>}/>
                    <Route path="main" element={<Main/>}>
                        <Route path="success" element={<Success/>}/>
                        <Route path="notice" element={<Notice/>}/>
                        <Route path="home" element={<Home/>}/>
                        <Route path="apply" element={<Apply/>}/>
                        <Route path="approval" element={<Approval/>}/>
                        <Route path="record" element={<Record/>}/>
                        <Route path="recordDetail" element={<RecordDetail/>}/>
                        <Route path="todo/batchProcess" element={<BatchProcess/>}/>
                        <Route path="approvalDetail" element={<Detail/>}/>
                    </Route>
                    <Route path="/admission" element={<Admission/>}/>
                    <Route path="/env" element={<Env/>}/>
                </Route>
            </Routes>
        </HashRouter>
    </React.StrictMode>
);