import React from 'react';
import styles from './index.module.scss';
import {visitorRecordApi, VisitorRecordResponse} from "../../../../../api/visitorRecord";
import {ForbidFill} from "antd-mobile-icons";
import IUtil from "../../../../../util/IUtil";

type PropsType = {
    data: VisitorRecordResponse
    refreshVisitorResponse: () => void
}

function Index({data, refreshVisitorResponse}: PropsType) {
    let displayIdentityCardNumber = data.identityCardNumber.substring(0, 5) + "****" + data.identityCardNumber.substring(data.identityCardNumber.length - 5)

    return (
        <div className={styles.Main}>
            <div style={{width: 50, display: 'flex', justifyContent: 'center'}} onClick={() => {
                IUtil.confirmDialog('是否删除此访客', () => {
                    visitorRecordApi.delete({identityCardNumber: data.identityCardNumber}).then(res => {
                        refreshVisitorResponse()
                    })
                })

            }}>
                <ForbidFill/>
            </div>
            <div className={styles.Right} onClick={() => {
            }}>
                <span className={styles.Name}> {data.name}</span>
                <div className={styles.Second}>
                    <span className={styles.Phone}>{data.phone}</span>
                    <span className={styles.IdentityCardNumber}>{displayIdentityCardNumber}</span>
                </div>
            </div>

        </div>
    );
}

export default Index;
