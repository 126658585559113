import React, {useContext} from 'react';
import styles from './index.module.scss';
import {useNavigate} from "react-router-dom";
import {Button, Form} from "antd-mobile";
import VisitorCommon from "./VisitorCommon";
import VisitorDetail from "./VisitorDetail";
import {approvalApi, VisitorDetailSaveRequest, VisitorSaveRequest} from "../../../api/apply";
import IUtil from "../../../util/IUtil";
import {StateContext} from "../../../state/StateContext";
import ArrUtil from "../../../util/ArrUtil";
import moment from "moment/moment";

export const detailEmptyData: VisitorDetailSaveRequest = {
    name: '',
    phone: '',
    idType: '',
    idTypeItem: ['身份证'],
    identityCardNumber: '',
    gender: '',
    genderItem: [],
    plateNumber: '',
    company: '',
    healthStatus: '',
    healthStatusItem: [],
    attachment: '',
    attachmentItem: []
}

const initData: VisitorSaveRequest = {
    startDate: '',
    endDate: '',
    reason: '',
    number: '',
    deptCode: '',
    details: [
        JSON.parse(JSON.stringify(detailEmptyData))
    ]
}

function Index() {
    const navigate = useNavigate()
    const stateContext = useContext(StateContext)
    const onFinish = (values: VisitorSaveRequest) => {
        IUtil.confirmDialog("访客系统只会生效每个人最新的访客申请记录，本次访客人员的提交将覆盖上次的申请，是否继续？", () => {
            if (values.startDateItem) {
                values.startDate = moment(values.startDateItem).format('YYYY-MM-DD\THH:mm:ss')
            }
            if (values.endDateItem) {
                values.endDate = moment(values.endDateItem).format('YYYY-MM-DD\T23:59:59')
            }
            values.details.forEach(detail => {
                detail.healthStatus = detail.healthStatusItem [0]
                detail.gender = detail.genderItem[0]
                detail.idType = detail.idTypeItem[0]
                if (!ArrUtil.isEmpty(detail.attachmentItem)) {
                    detail.attachment = detail.attachmentItem.map(e => e.url).join(",")
                }
            })
            if (stateContext.session.type === 'number') {
                approvalApi.saveSchool(values).then(res => {
                    navigate('/main/success')
                })
            } else {
                approvalApi.saveVisitor(values).then(res => {
                    navigate('/main/success')
                })
            }
        })
    }
    const [form] = Form.useForm();
    return <div className={styles.Main}>
        <Form className={styles.Content} layout={'horizontal'} form={form} initialValues={initData}
              onFinish={onFinish}
              footer={
                  <Button className={styles.Submit} block type='submit' size={'large'}>提交</Button>
              }>
            <div className={styles.Common}>
                <p className={styles.CommonTitle}>访客信息</p>
                <VisitorDetail form={form}/>
            </div>
            <div className={styles.Common}>
                <p className={styles.CommonTitle}>进校事由</p>
                <VisitorCommon form={form}/>
            </div>
        </Form>
    </div>
}

export default Index;
