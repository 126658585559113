import React, {ReactNode, useRef, useState} from 'react';
import styles from './index.module.scss';
import {Button, Popup, Radio, Space, Toast} from "antd-mobile";
import OrdinaryCar from "./OrdinaryCar";
import NewEnergyCar from "./NewEnergyCar";

type PropsType = {
    cancel: () => void
    visible: boolean
    onConfirm?: (val: string) => void
    value?: string
    children?: (item: (string | undefined)) => ReactNode;
}

function Index({visible, cancel, onConfirm, value, children}: PropsType) {
    const [type, setType] = useState<number>(1)
    const child = useRef<any>();
    let secondEl = <OrdinaryCar onRef={child}/>

    if (type === 2) {
        secondEl = <NewEnergyCar onRef={child}/>
    }
    const deleteInput = () => {
        child.current.deleteInput()
    }

    const complete = () => {
        let plateNo = child.current.complete();
        if (!plateNo) {
            Toast.show({
                icon: 'fail',
                content: '请输入完整车牌',
            })
        } else {
            if (onConfirm) {
                onConfirm(plateNo)
            }
            cancel()
        }

    }
    return (
        <div className={styles.Main}>
            {children ? children(value) : undefined}
            <Popup bodyStyle={{maxHeight: '100%', overflowY: 'scroll'}} visible={visible}>
                <div className={styles.Container}>
                    <div className={styles.First}>
                        <Radio.Group
                            value={type}
                            onChange={val => {
                                setType(val as number)
                            }}
                        >
                            <Space>
                                <Radio value={1}>普通车牌</Radio>
                                <Radio value={2}>新能源车牌</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                    <div className={styles.Second}>
                        {secondEl}
                    </div>

                    <div className={styles.Bottom}>
                        <Button onClick={cancel}>取消</Button>
                        <Button className={styles.BottomRight} color={"warning"} onClick={deleteInput}>删除</Button>
                        <Button color={"success"} onClick={complete}>完成</Button>
                    </div>
                </div>
            </Popup>
        </div>
    );
}

export default Index;
