import React, {useEffect} from 'react';
import styles from './index.module.scss';
import {useNavigate} from "react-router-dom";
import IUtil from "../../util/IUtil";
import {DotLoading} from "antd-mobile";


function Index() {

    const navigate = useNavigate();

    useEffect(() => {
        if (IUtil.isWework()) {
            console.log(2)
            navigate("/login/wework", {replace: true})
            return
        } else if (IUtil.isWechat()) {
            console.log(3)
            navigate("/login/wechat", {replace: true})
        } else if (IUtil.isCas()) {
            console.log(4)
            navigate("/login/cas", {replace: true})
        }
    });


    return (
        <div className={styles.Main}>
            <DotLoading color='currentColor'/>
        </div>
    );
}

export default Index;
