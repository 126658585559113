import React, {useContext, useEffect, useState} from 'react';
import styles from './index.module.scss';
import {Avatar, Input} from "antd-mobile";
import {useNavigate, useSearchParams} from "react-router-dom";
import {approvalApi, ApplyResponse} from "../../../../api/apply";
import StrUtil from "../../../../util/StrUtil";
import ItemProcess, {status2tag} from "../../../../common/items/ItemProcess";
import IUtil, {apply_tab_key} from "../../../../util/IUtil";
import {StateContext} from "../../../../state/StateContext";
import ItemCenter from "../../../../common/items/ItemCenter";
import moment from "moment/moment";
import {RightOutline} from 'antd-mobile-icons';
import VisitorDetail from "../../Record/RecordDetail/VisitorDetail";


function Index() {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const stateContext = useContext(StateContext)
    const [data, setData] = useState<ApplyResponse>()
    const [todoNum, setTodoNum] = useState<number>(0)
    const [comment, setComment] = useState<string>('')
    let id = searchParams.get('id');
    useEffect(() => {
        getData(id)
    }, [stateContext.session.username])
    const getData = (id: string | null) => {
        if (!id) {
            return
        }
        approvalApi.todoNum({approvalNode: {type: 1}}).then(res => {
            setTodoNum(res.data.body)
        })
        approvalApi.findById({id: id}).then(res => {
            setData(res.data.body)
        })
    }
    if (StrUtil.isEmpty(id) || !data) {
        return <div/>
    }
    const reject = () => {
        IUtil.confirmDialog('是否提交', () => {
            if (!id) {
                return
            }
            approvalApi.reject({id: id, comment: comment}).then(res => {
                getData(id)
            })
        })

    }
    const agree = () => {
        IUtil.confirmDialog('是否提交', () => {
            if (!id) {
                return
            }
            approvalApi.agree({id: id, comment: comment}).then(res => {
                getData(id)
            })
        })
    }
    let operationEl = undefined
    let height: string | undefined = 'auto'
    if (data.status === 0) {
        let approvalNode = data.approvalNodes.find(e => e.type === 1);
        if (approvalNode) {
            let people = approvalNode.approvalPeoples.find(e => e.number === stateContext.session.username);
            if (people) {
                height = undefined
                operationEl =
                    <div className={styles.Operation}>
                        <Input
                            className={styles.Comment}
                            placeholder='填写审批意见'
                            onChange={val => setComment(val)}
                        />
                        <div className={styles.Btn}>
                            <div className={styles.BtnItem} onClick={reject}>X 驳回</div>
                            <div className={`${styles.BtnItem} ${styles.BtnItem02}`} onClick={agree}>✔ 同意</div>
                        </div>
                    </div>
            }
        }
    }
    let submitterName = data.submitterName
    if (StrUtil.isEmpty(submitterName)) {
        submitterName = '校外人员'
    }
    return (
        <div className={styles.Main}>
            {/*<PullToRefresh onRefresh={async () => {*/}
            {/*}}>*/}
            <div className={styles.Detail} style={{height: height}}>
                <div className={styles.First} onClick={() => {
                    localStorage.setItem(apply_tab_key, '0')
                    navigate('/main/approval', {replace: true})
                }}>
                    <div className={styles.Left}><span>您有</span>
                        <div className={styles.Icon}>
                            <span>{todoNum}</span>
                        </div>
                        <span>条待处理的审批</span></div>
                    <RightOutline className={styles.RightIcon}/>
                </div>
                <div className={styles.Second}>
                    <Avatar style={{'--border-radius': '30px', '--size': '35px'}} src={''}/>
                    <span
                        className={styles.Title}>{submitterName}的访客进校申请</span>{status2tag.get(data.status)}
                </div>
                <div className={styles.Content}>
                    <ItemCenter label={'提交时间'} value={moment(data.createTime).format('YYYY-MM-DD HH:mm')}/>
                    <ItemCenter label={'开始时间'} value={moment(data.startDate).format('YYYY-MM-DD HH:mm')}/>
                    <ItemCenter label={'结束时间'} value={moment(data.endDate).format('YYYY-MM-DD HH:mm')}/>
                    <ItemCenter label={'被访人'} value={data.name}/>
                    <ItemCenter label={'被访部门'} value={data.deptName}/>
                    <ItemCenter label={'事由'} value={data.reason}/>
                    <VisitorDetail details={data.applyDetails}/>
                </div>
                <div className={styles.Process}>
                    <ItemProcess status={data.status} approvalNodes={data.approvalNodes} open={true}/>
                </div>
            </div>
            {/*</PullToRefresh>*/}
            {operationEl}
        </div>

    );
}

export default Index;
