import React from 'react';
import styles from './index.module.scss';
import ProcessItem from "./ProcessItem";


function Index() {

    return (
        <div className={styles.Main}>
            <ProcessItem type={1}/>
        </div>
    );
}

export default Index;
