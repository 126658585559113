import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {useLocation} from "react-router-dom";
import {ApplyResponse, approvalApi} from "../../../../api/apply";
import {Divider, PullToRefresh, Toast} from "antd-mobile";
import IUtil from "../../../../util/IUtil";
import ItemCenter from "../../../../common/items/ItemCenter";
import {Time} from "@icon-park/react";
import IButton from "../../../../common/IButton";
import ItemProcess from "../../../../common/items/ItemProcess";
import moment from "moment/moment";
import VisitorDetail from "./VisitorDetail";


function Index() {
    const {state} = useLocation();
    const [applyResponse, setApprovalResponse] = useState<ApplyResponse>()
    const getData = (id: string) => {
        approvalApi.findById({id: id}).then(res => {
            setApprovalResponse(res.data.body)
        })
    }
    useEffect(() => {
        const id: string = state as string
        if (id) {
            getData(id)
        } else {
            Toast.show('此页面已失效，请从主页从新进入')
        }
    }, [])
    if (!applyResponse) {
        return <div/>
    }

    const withdraw = () => {
        IUtil.confirmDialog('您确定要撤销当前审批吗？', () => {
            approvalApi.withdraw({id: applyResponse.id}).then(res => {
                getData(applyResponse.id)
            })
        })
    }
    let withdrawEl
    switch (applyResponse.status) {
        case 0:
            withdrawEl = <IButton text={'撤销'} style={{marginTop: '20px'}} onClick={withdraw}/>
            break
        case 1:

            break
    }
    let approvalNodeResponses = applyResponse.approvalNodes;
    let hasComment = approvalNodeResponses.find(e => e.approvalPeoples.find(e2 => e2.comment));
    return (
        <PullToRefresh onRefresh={async () => getData(applyResponse.id)}>
            <div className={styles.Main}>
                <ItemCenter label={<><Time style={{marginRight: '5px'}}/>申请时间</>}
                            value={moment(applyResponse.createTime).format('YYYY-MM-DD HH:mm:ss')}/>
                <Divider/>
                <ItemCenter label={'开始时间'} value={moment(applyResponse.startDate).format('YYYY-MM-DD HH:mm')}/>
                <ItemCenter label={'结束时间'} value={moment(applyResponse.endDate).format('YYYY-MM-DD HH:mm')}/>
                <ItemCenter label={'被访人'} value={applyResponse.name}/>
                <ItemCenter label={'被访部门'} value={applyResponse.deptName}/>
                <ItemCenter label={'事由'} value={applyResponse.reason}/>
                <VisitorDetail details={applyResponse.applyDetails}/>
                <ItemProcess approvalNodes={approvalNodeResponses} status={applyResponse.status}
                             open={hasComment ? true : false}/>
                {withdrawEl}

            </div>
        </PullToRefresh>
    );
}

export default Index;
