import React, {useContext} from 'react';
import styles from './index.module.scss';
import {ApprovalPeopleResponse} from "../../../api/apply";
import {Avatar} from "antd-mobile";
import moment from "moment/moment";
import {StateContext} from "../../../state/StateContext";

export type PropsType = {
    data: ApprovalPeopleResponse
}

function Index({data}: PropsType) {
    const stateContext = useContext(StateContext)
    let commentEl = undefined
    if (data.comment) {
        commentEl = <div className={styles.Comment}><span>“</span>{data.comment}<span>”</span></div>

    }
    let avatarItemIconEl
    switch (data.status) {
        case 1:
            avatarItemIconEl = <img className={styles.AvatarItemIcon}
                                    src={require('../../../assets/icons/record/people_agree.png')}/>
            break
        case 2:
            avatarItemIconEl = <img className={styles.AvatarItemIcon}
                                    src={require('../../../assets/icons/record/people_reject.png')}/>
            break
    }
    let rightEl = undefined
    if (data.approvalTime) {
        let statusName = ''
        if (data.status === 1) {
            statusName = '同意'
        } else if (data.status === 2) {
            statusName = '驳回'
        }
        rightEl = <span className={styles.ApprovalTime}>{statusName}
            <div className={styles.Point}></div>
            {moment(data.approvalTime).format('MM/DD HH:mm')}</span>
    }
    let name = data.name
    if (stateContext.session.type !== 'number') {
        name = name.substring(0, 1) + new Array(name.length).join('*')
    }
    return (
        <div className={styles.Main}>
            <div className={styles.Left}>
                <div className={styles.AvatarItem}>
                    {avatarItemIconEl}
                    <Avatar className={styles.Avatar}
                            src={require('../../../assets/icons/record/people.png')}/>
                </div>
                <div className={styles.Name}>{name}</div>

            </div>
            {commentEl}
            {rightEl}
        </div>
    );
}

export default Index;
