import axios from 'axios'
import {Dialog} from "antd-mobile";
import authUtil from "./AuthUtil";
import qs from 'qs';
import ToastUtil from "./ToastUtil";

const excludeUrls: string[] = ['/auth/token/ykb', '/ticket/visitor/detail', '/ticket/approval/detail']

const service = axios.create({
    baseURL: process.env.REACT_APP_baseUrl, timeout: 10000
})

async function toLoginPage() {
    // await Dialog.alert({
    //     content: '登录已过期，即将刷新页面',
    // })
    // Dialog.clear()
    authUtil.saveToken('')
    // 跳转登录页之前保存一下现在的页面地址，方便登录成功后跳转回来
    authUtil.savePageUrl()
    // 如果使用HashRouter，那么页面跳转要用hash，不然页面就会加载空白或者是闪烁
    window.location.hash = '/login'
}

service.interceptors.request.use((config) => {
    if (!config.url) {
        return Promise.reject('url为空')
    }
    if (!config.headers) {
        config.headers = {}
    }
    if (config.method === 'get') {
        //如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
        config.paramsSerializer = function (params) {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }
    if (!excludeUrls.includes(config.url)) {
        let token = authUtil.getToken();
        // 没有token跳转到登录页
        if (!token) {
            toLoginPage()
            return Promise.reject('登录已失效');
        }
        // config.headers['token'] = token
        config.headers['MOBILE-USER-TOKEN'] = token
    } else {
        console.log('排除的url: ', config.url)
    }
    ToastUtil.showLoading()
    return config
}, error => {
    ToastUtil.hideLoading()
    return Promise.reject(error)
})

async function handleCode(code: number, errMsg: string) {
    switch (code) {
        case 6001:
            toLoginPage()
            break
        default:
            Dialog.alert({
                content: errMsg,
            })
            break
    }

}

service.interceptors.response.use(response => {
    ToastUtil.hideLoading()
    const data = response.data
    const errMsg = data.info
    if (data.code != 200) {
        handleCode(data.code, errMsg);
        return Promise.reject(errMsg)
    }
    return response
}, error => {
    ToastUtil.hideLoading()
    return Promise.reject(error)
})

export default service
