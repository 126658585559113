import React, {useContext, useState} from 'react';
import styles from './index.module.scss';
import {useNavigate} from "react-router-dom";
import ThirdItem from "./ThirdItem";
import applyRecordIcon from '../../../assets/images/home/applyRecord.svg'
import leaveApplyIcon from '../../../assets/images/home/leaveApply.svg'
import {StateContext} from "../../../state/StateContext";
import notice from "../../../assets/images/home/notice.svg";


function Index() {
    const navigate = useNavigate();
    const [noticeShow, setNoticeShow] = useState<boolean>(false)
    const stateContext = useContext(StateContext)
    return (
        <div className={styles.Main}>
            <div className={styles.Banner}>
                <img src={require('../../../assets/images/home/banner.png')}/>
            </div>
            <div className={styles.First}>
                <div className={styles.FirstRight}>
                    <div className={styles.Hello}>{'云南中医药大学'}</div>
                    <div className={styles.Info}>

                        {stateContext.session.type === 'number' ?
                            <>
                                <div className={styles.Name}>{stateContext.session.name}</div>
                                <div className={styles.Number}>{stateContext.session.username}</div>
                            </> : <div className={styles.Name}>欢迎您!</div>}
                    </div>
                </div>
            </div>

            <div className={styles.Second}>
                <ThirdItem title={'访客申请'} desc={'填写进校时间，理由等'} onClick={() => navigate('/main/apply')}
                           icon={leaveApplyIcon}/>
                <ThirdItem title={'申请记录'} desc={'查看申请记录进度，驳回理由等'} onClick={() => navigate('/main/record')}
                           icon={applyRecordIcon}/>
                {/*{*/}
                {/*    stateContext.session.type === 'number' ? <ThirdItem title={'我的审批'} desc={'查看我的审批'} onClick={() => navigate('/main/approval')}*/}
                {/*                                                        icon={applyRecordIcon}/> : undefined*/}
                {/*}*/}
                <ThirdItem title={stateContext.session.type === 'number' ? '我的审批' : '进校须知'}
                           desc={stateContext.session.type === 'number' ? '查看我的审批' : '进校需要注意的事项'} onClick={() => {
                    if (stateContext.session.type === 'number') {
                        navigate('/main/approval')
                    } else {
                        navigate('/main/notice')
                    }
                }} icon={notice}/>
            </div>
            {/*<div className={styles.Bottom}>*/}
            {/*    <div className={styles.BottomSecond}>支持：信息学院</div>*/}
            {/*</div>*/}
        </div>

    );
}

export default Index;
