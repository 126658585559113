// 创建store
import {LoginInfo} from "../api/token";

export const defaultSessionState: LoginInfo = {name: '', username: '', type: ''}

export type sessionAction =
    | { type: 'name', payload: string }
    | { type: 'username', payload: string }
    | { type: 'type', payload: string }
    | { type: 'session', payload: LoginInfo }

// reducer 创建
export const sessionReducer = (state: LoginInfo, action: sessionAction) => {
    switch (action.type) {
        case 'name':
            return {...state, name: action.payload}
        case 'username':
            return {...state, username: action.payload}
        case 'type':
            return {...state, type: action.payload}
        case 'session':
            return {...action.payload}
        default:
            return state
    }
}


