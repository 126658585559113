import React, {useEffect} from 'react';
import styles from './index.module.scss';
import {useNavigate, useSearchParams} from "react-router-dom";
import authUtil from "../../../util/AuthUtil";
import {DotLoading} from "antd-mobile";


function Index() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let token = searchParams.get('token');
        if (token) {
            authUtil.saveToken(token)
            authUtil.toPageUrl(navigate)

        } else {
            const appId: string | undefined = process.env.REACT_APP_appId
            let pageUrlHref = window.location.href;
            const pageUrl: string = pageUrlHref.replace(window.location.search, '')
            const wxcodeUrl = 'https://wxcode.cqhyxk.com/wxcode';
            const redirectUri: string = wxcodeUrl + '/auth/token/user/wechat?pageUrl=' + encodeURIComponent(pageUrl)
            const state = process.env.REACT_APP_wxcode_state
            const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`
            window.location.replace(authUrl)
        }
    }, []);


    return (
        <div className={styles.Main}>
            <DotLoading color='currentColor'/>
        </div>
    );
}

export default Index;
