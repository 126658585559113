import React, {useContext, useEffect, useState} from 'react';
import styles from './index.module.scss';
import {Form, Input} from "antd-mobile";
import DateRangePicker from "./DateRangePicker";
import {FormInstance} from "antd-mobile/es/components/form";
import {DeptMemberResponse, orgApi} from "../../../../api/org";
import DeptMemberPicker from "./DeptMemberPicker";
import {StateContext} from "../../../../state/StateContext";

type PropsType = {
    form: FormInstance
}

function Index({form}: PropsType) {
    const [deptResponses, setDeptResponses] = useState<DeptMemberResponse[]>([])
    const stateContext = useContext(StateContext)
    useEffect(() => {
        orgApi.listDeptMemberResponseUser().then(res => {
            setDeptResponses(res.data.body)
        })
    }, []);
    return (
        <div className={styles.Main}>
            <DateRangePicker form={form}/>
            {stateContext.session.type === 'number' ? undefined :
                <DeptMemberPicker form={form} deptResponses={deptResponses}/>}
            <Form.Item name='reason' label='进校事由' rules={[{required: true}]}>
                <Input placeholder='请描述进校事由' clearable/>
            </Form.Item>
        </div>
    );
}

export default Index;
