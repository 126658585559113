// 创建store
import {createContext, Dispatch} from "react";
import {LoginInfo} from "../api/token";
import {defaultSessionState, sessionAction} from "./sessionState";


type Context = {
    session: LoginInfo,
    sessionDispatch: Dispatch<sessionAction>,
}

// 创建Context放在最上层父组件
export const StateContext = createContext<Context>({
    session: defaultSessionState,
    sessionDispatch: () => undefined,
})


