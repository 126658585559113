import React, {useEffect, useReducer} from 'react';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {defaultSessionState, sessionReducer} from "../state/sessionState";
import {StateContext} from "../state/StateContext";
import {tokenApi} from "../api/token";
import {routeMenusMap} from "../index";
import Vconsole from "vconsole";

export const authPages: RegExp[] = [/.*\/main\/.*/]

function Index() {
    const location = useLocation();
    let pathname = location.pathname;
    if (pathname.endsWith('/')) {
        pathname = pathname.substring(0, pathname.length - 1)
    }
    const routerMenu = routeMenusMap.get(pathname)
    if (routerMenu) {
        document.title = routerMenu.name
    } else {
        document.title = '访客申请'
    }
    if (process.env.REACT_APP_vconsole === 'open') {
        new Vconsole();
    }
    const [session, sessionDispatch] = useReducer(sessionReducer, defaultSessionState)
    useEffect(() => {
        let regExp = authPages.find(e => e.test(location.pathname));
        if (regExp) {
            tokenApi.getLoginInfo().then(res => {
                sessionDispatch({type: 'session', payload: res.data.body})
                if (res.data.body.username === 'ofhEs5_ch4DxDhFlu3OXNFLVFbTY') {
                    new Vconsole()
                }
            })
        }

    }, [location.pathname])
    return (
        <StateContext.Provider value={{session, sessionDispatch}}>
            <Outlet/>
        </StateContext.Provider>

    );
}

export default Index;
