import {Dialog} from "antd-mobile";
import IdentityUtil from "./IdentityUtil";

const env_key = "visitor_h5_env_73b158a84ce4468da57b9663ab280b0f"
export const apply_tab_key = "apply_tab_key_73b158a84ce4468da57b9663ab280b0f"
export const approval_tab_key = "approval_tab_key_73b158a84ce4468da57b9663ab280b0f"

class IUtil {

    // 是否手机浏览器
    public static isMobile = () => {
        return window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i); // 是否手机端
    }

    // 是否微信
    public static isWechat = () => {
        return /micromessenger/i.test(navigator.userAgent);
    }

    // 是否企业微信
    public static isWework = () => {
        return /wxwork/i.test(navigator.userAgent);
    }

    public static setEnv = (env: string) => {
        localStorage.setItem(env_key, env)
    }

    // 是否应该cas登录
    public static isCas = () => {
        return "cas" === localStorage.getItem(env_key)
    }

    public static confirmDialog = async (content: string, access?: Function, reject?: Function) => {
        const result = await Dialog.confirm({
            content: content,
        })
        if (result) {
            if (access) {
                access()
            }
        } else {
            if (reject) {
                reject()
            }
        }
    }

    // 根据身份证计算性别
    public static calcGender = (identityCardNumber: string) => {
        if (!IdentityUtil.isValid(identityCardNumber)) {
            return undefined
        }
        let genderId = Number(identityCardNumber.charAt(16));
        return genderId % 2 === 0 ? '女' : '男'
    }


}

export default IUtil
