import React, {useState} from 'react';
import styles from './index.module.scss';
import {InfiniteScroll, PullToRefresh} from "antd-mobile";
import {approvalApi, ApplyQuery, ApplyResponse} from "../../../../api/apply";
import RecordItem from "./RecordItem";
import {PageResponse} from "../../../../common/DataType";

export type PropsType = {
    type: '审批中' | '已通过' | '其它'
}

function Index(props: PropsType) {
    const [approvalResponses, setApprovalResponses] = useState<ApplyResponse[]>([])
    const [hasMore, setHasMore] = useState(true)

    let params: ApplyQuery = {status: -1}
    switch (props.type) {
        case '审批中':
            params = {status: 0}
            break
        case '已通过':
            params = {status: 1}
            break
        case '其它':
            params = {statuses: [2, 3]}
            break
    }

    async function loadMore() {
        const current = Math.floor(approvalResponses.length / 5) + 1
        let axiosResponse = await approvalApi.pageMyApply(params,{current: current, size: 5});
        let data: PageResponse<ApplyResponse> = axiosResponse.data.body;
        const newApprovalResponses = [...approvalResponses, ...data.list];
        await setApprovalResponses(newApprovalResponses)
        await setHasMore(current < data.pages)
    }

    async function loadFirst() {
        await setApprovalResponses([])
        await setHasMore(true)
    }

    return (
        <PullToRefresh onRefresh={async () => {
            await loadFirst()
        }}>
            <div className={styles.Main}>
                <div className={styles.Total}>
                    共查询到{approvalResponses.length}条记录<span className={styles.Tip}>（下拉刷新）</span>
                </div>
                <div className={styles.Content}>
                    {approvalResponses.map(((item, index) => <RecordItem key={index} data={item}/>))}
                </div>
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}/>
        </PullToRefresh>

    );
}

export default Index;
