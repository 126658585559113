import React, {useEffect} from 'react';
import styles from './index.module.scss';
import {useNavigate, useSearchParams} from "react-router-dom";
import authUtil from "../../../util/AuthUtil";
import {DotLoading} from "antd-mobile";


function Index() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            authUtil.saveToken(token)
            authUtil.toPageUrl(navigate)
        } else {
            const appId: string | undefined = process.env.REACT_APP_companyId
            let pageUrlHref = window.location.href;
            const pageUrl: string = pageUrlHref.replace(window.location.search, '')
            const redirectUri: string = process.env.REACT_APP_baseUrl + '/auth/token/user/wework?pageUrl=' + encodeURIComponent(pageUrl)
            const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=snsapi_base&state=LEAVE#wechat_redirect`
            window.location.replace(authUrl)
        }
    }, []);


    return (
        <div className={styles.Main}>
            <DotLoading color='currentColor'/>
        </div>
    );
}

export default Index;
