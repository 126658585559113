import {IBaseResponse} from "../common/DataType";
import request from "../util/request";

export type Member = {
    number: string //工号
    name: string //姓名
}

export type DeptMemberResponse = {
    code: string //编码
    name: string //名称
    // members: Member[] //成员列表
    parentCode?:string // 编码
    type?:number // 组织类型
}

export const orgApi = {
    /**
     * 查询所有部门
     */
    listDeptMemberResponseUser: () => {
        return request.get<IBaseResponse<DeptMemberResponse[]>>("/mapi/org/list/deptMember/user");
    },
    /**
     * 根据部门id查询所有部门成员(用户端使用)
     */
    listDeptMemberResponseUserCode: (params:{code: string,name?:string}) => {
        return request.get<IBaseResponse<Member[]>>("/mapi/org/list/deptMember/user/code",{params});
    }
}
