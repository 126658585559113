import React, {CSSProperties} from 'react';
import styles from './index.module.scss';

type PropsType = {
    text: string
    onClick?: () => void
    style?: CSSProperties
}

function Index(props: PropsType) {

    return (
        <div className={styles.Main} onClick={props.onClick} style={props.style}>
            <span>{props.text}</span>
        </div>
    );
}

export default Index;
