import React from 'react';
import styles from './index.module.scss';
import {ImageUploader} from "antd-mobile";
import {ImageUploadItem} from "antd-mobile/es/components/image-uploader";
import {emptyImageUploadItemPromise} from "../../DataType";

type PropsType = {
    label: string
    urls: string[]
}

function Index({label, urls}: PropsType) {
    if (!urls) {
        return <div/>
    }
    const fileIdsItem: ImageUploadItem[] = urls.map(url => {
        const imageUploadItem: ImageUploadItem = {
            url: url,
        }
        return imageUploadItem;
    })
    if (!fileIdsItem || fileIdsItem.length === 0) {
        return <div/>
    }
    return (
        <div className={styles.Main}>
            <div className={styles.ItemLeft}>
                <p>{label}</p>
            </div>
            <div className={styles.ItemRight}><ImageUploader
                style={{'--cell-size': '40px'}}
                className={styles.ItemRight}
                value={fileIdsItem}
                showUpload={false}
                deletable={false}
                upload={() => emptyImageUploadItemPromise}
            /></div>
        </div>
    );
}

export default Index;
