import React from 'react';
import styles from './index.module.scss';
import {Tag} from "antd-mobile";
import People from "./People";
import {ApprovalNodeResponse} from "../../api/apply";

export type PropsType = {
    data: ApprovalNodeResponse
    last: boolean
}
const status2name = new Map<number, string>()
status2name.set(0, '审批中')
status2name.set(1, '已同意')
status2name.set(2, '已驳回')
status2name.set(3, '已撤回')

function Index({data, last}: PropsType) {
    let statusIconEl = <img src={require('../../assets/icons/record/underApproval.png')} width={18} height={18}/>

    switch (data.status) {
        case 1:
            statusIconEl = <img src={require('../../assets/icons/record/aggree.png')} width={18} height={18}/>
            break
        case 2:
            statusIconEl = <img src={require('../../assets/icons/record/reject.png')} width={18} height={18}/>
            break
    }
    let processBarColor = ''
    let statusName
    switch (data.type) {
        case 0:
            statusName = ''
            break
        case 1: // 处理中
            statusName = '审批中'
            processBarColor = '#b4b4b4'
            break
        case 2: // 已经处理过了
            processBarColor = data.status === 1 ? '#0091ff' : '#b4b4b4'
            statusName = status2name.get(data.status)
            break
    }
    return (
        <div className={styles.Main}>
            <div className={styles.Head}>
                {statusIconEl}
                <div className={styles.Title}>审批人</div>
                {statusName ? <>
                    <div className={styles.Point}></div>
                    <div className={styles.Title}>{statusName}</div>
                </> : undefined}

                {data.approvalPeoples.length > 1 ? <Tag color='#2db7f5'>{data.meeting ? '会签' : '或签'}</Tag> : undefined}
            </div>
            <div className={styles.Content}>
                <div className={styles.ProcessBar} style={{backgroundColor: last ? '' : processBarColor}}></div>
                <div className={styles.Peoples}>
                    {data.approvalPeoples.map((item, index) => {
                        return <People key={index} data={item}/>
                    })}
                </div>
            </div>
        </div>
    );
}

export default Index;
