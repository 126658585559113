import {IBaseResponse} from "../common/DataType";
import request from "../util/request";

export type VisitorRecordResponse = {
    submitter: string //提交人账号：工号/openid
    name: string //姓名
    phone: string //电话
    idType: string //证件类型
    identityCardNumber: string //证件号
    gender: string //性别
    plateNumber: string //车牌号
    company: string //单位
    healthStatus: string //健康状态

}

export const visitorRecordApi = {
    /**
     * 查询所有部门
     */
    list: () => {
        return request.get<IBaseResponse<VisitorRecordResponse[]>>("/mapi/visitorRecord/list");
    },
    /**
     * 根据身份证删除登陆者所填写过的访客
     */
    delete: (params: {identityCardNumber: string}) => {
        return request.delete<IBaseResponse<any>>("/mapi/visitorRecord", {params});
    }
}
