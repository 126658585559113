import {IBaseResponse, PageQuery, PageResponse} from "../common/DataType";
import request from "../util/request";
import {ImageUploadItem} from "antd-mobile/es/components/image-uploader";
import {ApplyDetailResponse} from "./applyDetail";

export type ApplyQueryChild01 = {
    vagueName?: string //姓名（模糊查询）
    vaguePhone?: string //电话（模糊查询）
    vagueIdentityCardNumber?: string //证件号（模糊查询）
    vaguePlateNumber?: string //车牌号（模糊查询）
    vagueCompany?: string //单位（模糊查询）
}

export type ApplyQueryChild02 = {
    vagueName?: string //被访人姓名（模糊查询）
}

export type ApplyQueryChild04 = {
    type?: number //处理状态
}

export type ApplyQueryChild05 = {
    number?: string //账号
}

export type ApplyQuery = {
    submitter?: string //提交人账号：工号/openid
    school?: boolean //true: 校内人员提交的，false: 校外人员提交的
    createTimeMax?: string //创建时间范围结束值
    createTimeMin?: string //创建时间范围开始值
    status?: number //审批状态
    statuses?: number[] //审批状态列表
    deptCode?: string //被访部门编码
    deptCodeItem?: string //被访部门编码
    number?: string //被访人工号
    contentStartDateMax?: string //申请结束时间
    contentEndDateMin?: string //申请开始时间
    mame?: string //被访人姓名
    applyDetail?: ApplyQueryChild01 //申请详情
    user?: ApplyQueryChild02 //用户
    approvalNode?: ApplyQueryChild04 //审批节点
    approvalPeople?: ApplyQueryChild05 //审批人

}

export type ApplyResponse = {
    id: string //主键id
    submitter: string //提交人账号：工号/openid
    submitterName: string //提交人姓名
    school: null //true: 校内人员提交的，false: 校外人员提交的
    status: number //审批状态
    startDate: string //起始日期
    endDate: string //截止日期
    deptCode: string //被访部门编码
    deptName: string //被访部门名称
    number: string //被访人工号
    name: string //被访人姓名
    reason: string //申请事由
    createTime: string //创建时间
    updateTime: string //更新时间
    approvalNodes: ApprovalNodeResponse[] //审批节点
    applyDetails: ApplyDetailResponse[] //申请详情列表

}

export type ApprovalPeopleResponse = {
    id: string //主键id
    approvalNodeId: string //审批节点id
    number: string //账号
    name: string //姓名
    comment: string //评论
    status: number //审批状态
    approvalTime: string //审批时间

}

export type ApprovalNodeResponse = {
    id: string //主键id
    applyId: string //申请id
    type: number //处理状态
    meeting: null //true: 会签， false: 或签
    status: number //审批状态
    send: null //是否发送过通知了
    approvalPeoples: ApprovalPeopleResponse[] //审批人

}

export type VisitorSaveRequest = {
    number: string //被访人工号
    name?: string //被访人姓名
    deptCode: string //被访部门编码
    deptName?: string //被访部门名称
} & SchoolSaveRequest

export type SchoolSaveRequest = {
    startDate: string //开始时间
    startDateItem?: Date //开始时间
    endDate: string //结束时间
    endDateItem?: Date //结束时间
    reason: string //申请事由
    details: VisitorDetailSaveRequest[] //详情列表

}

export type VisitorDetailSaveRequest = {
    name: string //姓名
    phone: string //电话
    idType: string //证件类型
    idTypeItem: string[] //证件类型
    identityCardNumber: string //证件号
    gender: string //性别
    genderItem: string[] //性别
    plateNumber: string //车牌号
    company: string //单位
    healthStatus: string //健康状态
    healthStatusItem: string[] //健康状态
    attachment: string //附件（逗号分隔的文件链接）
    attachmentItem: ImageUploadItem[] //附件（逗号分隔的文件链接）

}

export const approvalApi = {
    /**
     * 校内人员访客申请
     */
    saveSchool: (data: SchoolSaveRequest) => {
        return request.post<IBaseResponse<any>>("/mapi/apply/school", data);
    },
    /**
     * 校外人员访客申请
     */
    saveVisitor: (data: VisitorSaveRequest) => {
        return request.post<IBaseResponse<any>>("/mapi/apply/visitor", data);
    },
    /**
     * 分页(我的申请)
     */
    pageMyApply: (data: ApplyQuery , params: PageQuery) => {
        return request.post<IBaseResponse<PageResponse<ApplyResponse>>>("/mapi/apply/page/my/apply", data, {params});
    },
    /**
     * 分页(我审批的)
     */
    pageMyApproval: (data: ApplyQuery , params: PageQuery) => {
        return request.post<IBaseResponse<PageResponse<ApplyResponse>>>("/mapi/apply/page/my/approval", data, {params});
    },
    /**
     * 查询单个详情
     */
    findById: (params: { id: string }) => {
        return request.get<IBaseResponse<ApplyResponse>>("/mapi/apply", {params});

    },
    /**
     * 撤销
     */
    withdraw: (params: { id: string }) => {
        return request.put<IBaseResponse<any>>("/mapi/apply/withdraw", {}, {params});
    },
    /**
     * 同意
     */
    agree: (params: { id: string, comment?: string }) => {
        return request.put<IBaseResponse<any>>("/mapi/apply/agree", {}, {params});
    },
    /**
     * 批量同意
     */
    batchAgree: (data: { ids: string[], comment?: string }) => {
        return request.put<IBaseResponse<any>>("/mapi/apply/agree/batch", data);
    },
    /**
     * 驳回
     */
    reject: (params: { id: string, comment?: string }) => {
        return request.put<IBaseResponse<any>>("/mapi/apply/reject", {}, {params});
    },
    /**
     * 批量驳回
     */
    batchReject: (data: { ids: string[], comment?: string }) => {
        return request.put<IBaseResponse<any>>("/mapi/apply/reject/batch", data);
    },
    /**
     * 数量(给审批人的查询接口,1:处理中 2:已处理)
     */
    todoNum: (data: ApplyQuery) => {
        return request.post<IBaseResponse<number>>("/mapi/apply/todoNum", data);
    },

}
