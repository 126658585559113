import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {Button, Form, Input, PickerView, Popup, SearchBar, Toast} from "antd-mobile";
import {FormInstance} from "antd-mobile/es/components/form";
import {DeptMemberResponse, Member, orgApi} from "../../../../../api/org";
import {VisitorSaveRequest} from "../../../../../api/apply";


type PropsType = {
    form: FormInstance
    deptResponses: DeptMemberResponse[]
}


type DeptType = {
    label: string
    value: string
    members: Member[]
}

type MemberType = {
    label: string
    value: string
    name: string
}

function Index({form, deptResponses}: PropsType) {
    const [showMember, setShowMember] = useState<boolean>(false)
    const [showDept, setShowDept] = useState<boolean>(false)
    const [depts, setDepts] = useState<DeptType[]>([])
    const [tempSelectedCode, setTempSelectedCode] = useState<string>()
    const [selectedCode, setSelectedCode] = useState<string>()
    const [selectedNumber, setSelectedNumber] = useState<string>()
    const [deptQ, setDeptQ] = useState<string>()
    const [memberQ, setMemberQ] = useState<string>()
    const [members, setMembers] = useState<MemberType[]>([])

    let data: VisitorSaveRequest = form.getFieldsValue();
    useEffect(() => {
        if (deptResponses && deptResponses.length > 0) {
            setDepts(deptResponses.map(e => {
                return {
                    label: e.name,
                    value: e.code,
                    members: []
                };
            }));
        }
        if (deptResponses && deptResponses.find(e => e.code === data.deptCode)) {
            setSelectedCode(data.deptCode)
        }
    }, [deptResponses, data.deptCode, data.number]);

    const getUsers=()=>{
        if (data?.deptCode) {
            orgApi.listDeptMemberResponseUserCode({code: data.deptCode, name: memberQ ||'' }).then(res => {
                setMembers(res.data.body.map(e => {
                    return {
                        label: e.name + "   *****" + e.number.substring(e.number.length - 3),
                        name: e.name,
                        value: e.number
                    };
                }))
            })
        }
    }

    const selectDeptConfirm = () => {
        const dept = depts.find(e => e.value === tempSelectedCode);
        if (dept && data.deptCode != dept.value) {
            data.deptCode = dept.value
            data.deptName = dept.label
            data.number = ''
            data.name = ''
            form.setFieldsValue(data)
        }
        setSelectedCode(tempSelectedCode)
        setShowDept(false)
    }
    let filterDepts = depts
    if (deptQ) {
        filterDepts = filterDepts.filter((e: any) => e.label.indexOf(deptQ) >= 0)
    }


    const openMemberPicker = () => {
        const dept = depts.find(e => e.value === selectedCode);
        console.log(dept)
        if (!dept) {
            Toast.show("请先选择部门")
            return
        }

        setMembers([])
        setShowMember(true)
    }

    const selectMemberConfirm = () => {
        const member = members.find(e => e.value === selectedNumber);
        if (member) {
            data.number = member.value
            data.name = member.name
            form.setFieldsValue(data)
        }
        setShowMember(false)
        setMemberQ('')
    }

    return (
        <div className={styles.Main}>
            <Form.Item name='deptCode' hidden={true}><Input/></Form.Item>
            <Form.Item name='deptName' label='到访单位' onClick={() => setShowDept(true)}
                       rules={[{required: true}]}>
                <Input placeholder='请选择部门' readOnly/>
            </Form.Item>
            <Form.Item name='number' hidden={true}><Input/></Form.Item>
            <Form.Item name='name' label='被访人' onClick={openMemberPicker}
                       rules={[{required: true}]}>
                <Input placeholder='请选择被访人' readOnly/>
            </Form.Item>
            <Popup visible={showDept}>
                <div className={styles.DeptHeader}>
                    <SearchBar placeholder='请输入内容' onChange={setDeptQ}/>
                    <div className={styles.DeptHeaderBtn}>
                        <Button onClick={() => setShowDept(false)}>取消</Button>
                        <Button className={styles.DeptHeaderBtnConfirm} color='success'
                                onClick={selectDeptConfirm}>确定</Button>
                    </div>
                </div>
                <PickerView
                    columns={[filterDepts]}
                    onChange={(val, extend) => {
                        console.log(val)
                        setTempSelectedCode(val[0] as string)
                    }}
                />
            </Popup>

            <Popup visible={showMember}>
                <div className={styles.DeptHeader}>
                    <SearchBar placeholder='输入全名才能搜索到被访人' value={memberQ} onChange={setMemberQ} />
                    <div className={styles.DeptHeaderBtn}>
                        <Button onClick={() => {
                            setShowMember(false)
                            setMemberQ('')
                        }}>取消</Button>
                        <Button className={styles.DeptHeaderBtnConfirm} color='success'
                                onClick={getUsers}>搜索</Button>
                        <Button className={styles.DeptHeaderBtnConfirm} style={{marginLeft: '10px'}} color='success' disabled={members?.length === 0}
                                onClick={selectMemberConfirm}>确定</Button>

                    </div>
                </div>
                <PickerView
                    columns={[members]}
                    onChange={(val, extend) => {
                        setSelectedNumber(val[0] as string)
                    }}
                />
            </Popup>
        </div>
    );
}

export default Index;
