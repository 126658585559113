import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {Tabs} from "antd-mobile";
import StatusItem from "./StatusItem";
import {apply_tab_key} from "../../../util/IUtil";

function Index() {
    const [activeKey, setActiveKey] = useState<string>('0')
    useEffect(() => {
        let key = localStorage.getItem(apply_tab_key);
        if (key) {
            setActiveKey(key)
        }
    }, [])
    const setKey = (key: string) => {
        localStorage.setItem(apply_tab_key, key)
        setActiveKey(key)
    }
    return (
        <div className={styles.Main}>
            <Tabs className={styles.Tab} activeKey={activeKey} style={{'--content-padding': '0'}}
                  onChange={key => setKey(key)}>
                <Tabs.Tab title='审批中' key='0' className={styles.TabHeader}>
                    <StatusItem type={'审批中'}/>
                </Tabs.Tab>
                <Tabs.Tab title='已通过' key='1' className={styles.TabHeader}>
                    <StatusItem type={'已通过'}/>
                </Tabs.Tab>
                <Tabs.Tab title='已驳回/撤回' key='-1' className={styles.TabHeader}>
                    <StatusItem type={'其它'}/>
                </Tabs.Tab>
            </Tabs>
        </div>
    );
}

export default Index;
