import React from 'react';
import styles from './index.module.scss';
import Lattice from "../Lattice";

type PropsType = {
    plateNo: string[]
    idx: number
    changeIdx: (idx: number) => void
}

function Index(props: PropsType) {
    return (
        <div className={styles.Main}>
            {props.plateNo.map((item, index) => {
                if (index === 2) {
                    return <div key={index} className={styles.PointContainer}>
                        <div className={styles.Point}/>
                        <Lattice selected={props.idx === index} value={item}
                                 onClick={() => props.changeIdx(index)}></Lattice>
                    </div>
                }

                return <Lattice key={index} selected={props.idx === index} value={item}
                                onClick={() => props.changeIdx(index)}></Lattice>
            })}
        </div>
    );
}

export default Index;
