import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {useSearchParams} from "react-router-dom";
import {NavBar} from "antd-mobile";

import pass_expire from '../../../assets/images/admission/pass_expired.svg'
import moment from "moment/moment";
import pass_valid from '../../../assets/images/admission/pass_valid.svg'
import pass_invalid from '../../../assets/images/admission/pass_invalid.svg'
import voided from '../../../assets/images/admission/voided.png'
import InfoItem from "./InfoItem";
import {ticketApi, TicketVisitorDetail} from "../../../api/ticket";
import StrUtil from "../../../util/StrUtil";


function Index() {
    const [ticketVisitorDetail, setTicketVisitorDetail] = useState<TicketVisitorDetail>()
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let ticketEncryptStr = searchParams.get('ticketEncryptStr');
        let id = searchParams.get('id');
        if (ticketEncryptStr && id) {
            ticketApi.approvalDetail({
                ticketEncryptStr: ticketEncryptStr,
                id: id
            }).then(res => {
                let data = res.data.body;
                setTicketVisitorDetail(data)
            })
        }
    }, []);

    let contentEl = undefined
    if (ticketVisitorDetail) {

        let desc
        let icon
        const now = moment()
        const start = moment(ticketVisitorDetail.startDate)
        const end = moment(ticketVisitorDetail.endDate)
        let plateEl = undefined
        if (ticketVisitorDetail.plateNumber) {
            plateEl = <InfoItem label={'车牌号'} value={ticketVisitorDetail.plateNumber}/>
        }
        let startDate = ''
        if (!StrUtil.isEmpty(ticketVisitorDetail.startDate)) {
            startDate = moment(ticketVisitorDetail.startDate).format('YYYY-MM-DD HH:mm')
        }
        let endDate = ''
        if (!StrUtil.isEmpty(ticketVisitorDetail.endDate)) {
            endDate = moment(ticketVisitorDetail.endDate).format('YYYY-MM-DD HH:mm')
        }
        if (ticketVisitorDetail.newest) {
            if (now > end) {
                desc = '您当前的入校申请已过期，请重新申请。'
                icon = pass_expire
            } else if (now >= start) {
                desc = '您当前的入校申请可通行，请在通行结束时间前使用。'
                icon = pass_valid
            } else {
                desc = '您当前的入校申请未生效，请等待。'
                icon = pass_invalid
            }

            contentEl = <div className={styles.Second}>
                <div className={styles.Desc}>{desc}</div>
                <div className={styles.Icon}>
                    <img src={icon}/>
                </div>
                <div className={styles.Notice}>请您遵守学校的相关规定，积极配合检查核验。</div>
                <div className={styles.Info}>
                    <InfoItem label={'姓名'} value={ticketVisitorDetail.name}/>
                    <InfoItem label={'证件类型'} value={ticketVisitorDetail.idType}/>
                    <InfoItem label={'证件号'} value={ticketVisitorDetail.identityCardNumber}/>
                    {plateEl}
                    <InfoItem label={'通行开始时间'} value={startDate}/>
                    <InfoItem label={'通行结束时间'} value={endDate}/>
                </div>
            </div>
        } else {
            desc = '以下情况访客申请会被作废：1 您已经有新的访客申请，故本条申请作废、2 学校管理员删除了您的访客记录'
            icon = voided
            contentEl = <div className={styles.Second}>
                <div className={styles.Desc} style={{color: 'red'}}>{desc}</div>
                <div className={styles.Icon}>
                    <img src={icon}/>
                </div>
                <div className={styles.Notice}>请您遵守学校的相关规定，积极配合检查核验。</div>
                <div className={styles.Info}>
                    <InfoItem label={'姓名'} value={ticketVisitorDetail.name}/>
                    <InfoItem label={'证件类型'} value={ticketVisitorDetail.idType}/>
                    <InfoItem label={'证件号'} value={ticketVisitorDetail.identityCardNumber}/>
                    {plateEl}
                    <InfoItem label={'通行开始时间'} value={startDate}/>
                    <InfoItem label={'通行结束时间'} value={endDate}/>
                </div>
            </div>

        }

    }

    return (
        <div className={styles.Main}>
            <NavBar backArrow={false} style={{background: '#409EFF', color: 'white'}}>进校凭证</NavBar>
            <div className={styles.First}/>
            {contentEl}
        </div>
    );
}

export default Index;
