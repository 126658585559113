import React, {CSSProperties} from 'react';
import styles from './index.module.scss';

type PropsType = {
    title: string
    desc: string
    onClick: () => void
    icon: string
    style?: CSSProperties
}

function Index(props: PropsType) {

    return (
        <div className={styles.Main} style={props.style} onClick={props.onClick}>
            <div className={styles.Left}>
                <p className={styles.Title}>{props.title}</p>
                <p className={styles.Desc}>{props.desc}</p>
            </div>
            <img className={styles.Icon} src={props.icon}/>
        </div>
    );
}

export default Index;
