import React, {CSSProperties} from 'react';
import styles from './index.module.scss';

type PropsType = {
    label: string | React.ReactNode
    value: string | React.ReactNode
    rightEl?: React.ReactNode
    onClick?: () => void
    style?: CSSProperties
}

function Index({label, value, rightEl, onClick, style}: PropsType) {

    return (
        <div style={style} className={styles.Main} onClick={onClick}>
            <div className={styles.ItemLeft}>
                {label}
            </div>
            <div className={styles.ItemRight}>{value}</div>
            {rightEl}
        </div>
    );
}

export default Index;
