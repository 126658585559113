import React from 'react';
import styles from './index.module.scss';
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";
import IDivider from "../IDivider";
import {status2tag} from "../items/ItemProcess";
import {ApplyResponse} from "../../api/apply";
import ItemCenter from "../items/ItemCenter";
import StrUtil from "../../util/StrUtil";

type PropsType = {
    data: ApplyResponse
    disableClick?: boolean
}

function Index({data, disableClick}: PropsType) {
    const navigate = useNavigate();
    let visitorDetail = data.applyDetails[0];
    let submitterName = data.submitterName
    if (StrUtil.isEmpty(submitterName)) {
        submitterName = '校外人员'
    }
    return (
        <div className={styles.Main} onClick={() => disableClick ? {} : navigate('/main/approvalDetail?id=' + data.id)}>
            <IDivider/>
            <div className={styles.Content}>
                <div className={styles.First}>
                    <span className={styles.Title}>{submitterName}的访客进校申请</span>{status2tag.get(data.status)}
                    <span className={styles.CreateTime}>{moment(data.createTime).format('MM-DD')}</span>
                </div>
                <div>
                    <ItemCenter label={'姓名'} value={visitorDetail.name}/>
                    <ItemCenter label={'单位'} value={visitorDetail.company}/>
                    <ItemCenter label={'事由'} value={data.reason}/>
                </div>
            </div>
        </div>
    );
}

export default Index;

