import {NavigateFunction} from "react-router-dom";
import {authPages} from "../pages/Root";

let token_key: string = "visitor-h5_token_0bbe09b54fbc4dbaa4c56c2076f460b4";
let page_url_key: string = "visitor-h5_pageUrl_0bbe09b54fbc4dbaa4c56c2076f460b4";


class AuthUtil {
    public static logout = (navigate: NavigateFunction) => {
        localStorage.removeItem(token_key)
        localStorage.removeItem(page_url_key)
        navigate('/login')
    }
    public static saveToken = (token: string) => {
        localStorage.setItem(token_key, token)
    }
    public static getToken = () => {
        // if (IUtil.isVconsole()) {
        //     return 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJudW1iZXIkMjAyMTcxMCRTVC00NjMzNC1qd2dLNlFESU5sNHhtaDFrejM0V3QwVTZ2dzBsb2NhbGhvc3QiLCJpYXQiOjE2NDkwMDI1MzksImV4cCI6MTY0OTg2NjUzOX0.IK10w4ZL_DbFlqVgY1bE20ZiIg3H76NY-k5CiAwLmK4'
        // }
        let token = localStorage.getItem(token_key);
        return token;
    }

    public static savePageUrl = () => {
        let value = window.location.href;
        let regExp = authPages.find(e => e.test(value));
        if (regExp) {
            let newValue = value.replace(new RegExp("\\?clicktime=\\d+"), "");
            localStorage.setItem(page_url_key, newValue)
        }
    }

    public static getPageUrl = () => {
        let pageUrl = localStorage.getItem(page_url_key);
        return pageUrl;
    }

    public static toPageUrl = (navigate: NavigateFunction) => {
        let pageUrl = AuthUtil.getPageUrl();
        localStorage.setItem(page_url_key, '')
        if (pageUrl) {
            // 回到登录前的页面
            window.location.replace(pageUrl)
        } else {
            // 跳转到主页
            navigate("/main/home", {replace: true})
        }

    }
}

export default AuthUtil





