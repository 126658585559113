class IdentityUtil {

    public static isValid = (code: string): boolean => {
        const idCardReg = /^[1-9]\d{5}(19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i; // 身份证格式正则表达式
        let isPass = true; // 身份证验证是否通过（true通过、false未通过）

        // 如果身份证不满足格式正则表达式
        if (!code) {
            isPass = false;
        } else if (!code.match(idCardReg)) {
            isPass = false;
        } else if (code.length === 18) {
            // 18位身份证需要验证最后一位校验位
            const splits: string[] = code.split('');
            // ∑(ai×Wi)(mod 11)
            // 加权因子
            const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            // 校验位
            const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
            let sum = 0;
            let ai = 0;
            let wi = 0;
            for (let i = 0; i < 17; i++) {
                ai = parseInt(splits[i]);
                wi = factor[i];
                sum += ai * wi; // 开始计算并相加
            }
            const last = parity[sum % 11]; // 求余
            if (last.toString() !== splits[17]) {
                isPass = false;
            }
        }
        return isPass
    }

}

export default IdentityUtil