import React, {useEffect} from 'react';
import styles from './index.module.scss';
import {useNavigate, useSearchParams} from "react-router-dom";
import authUtil from "../../../util/AuthUtil";
import {DotLoading} from "antd-mobile";


function Index() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            authUtil.saveToken(token)
            authUtil.toPageUrl(navigate)
        } else {
            let pageUrlHref = window.location.href;
            const pageUrl: string = pageUrlHref.replace(window.location.search, '')
            const redirectUri: string = process.env.REACT_APP_baseUrl + '/auth/token/user/cas?pageUrl=' + encodeURIComponent(pageUrl)
            const authUrl = process.env.REACT_APP_casUrl + `/login?service=${encodeURIComponent(redirectUri)}`
            window.location.replace(authUrl)
        }
    }, []);


    return (
        <div className={styles.Main}>
            <DotLoading color='currentColor'/>
        </div>
    );
}

export default Index;
