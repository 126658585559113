import React from 'react';
import styles from './index.module.scss';
import {Divider} from "antd-mobile";
import {Time} from "@icon-park/react";
import moment from "moment/moment";
import ItemCenter from "../../../../../common/items/ItemCenter";
import {ApplyResponse} from "../../../../../api/apply";
import ItemProcess from "../../../../../common/items/ItemProcess";
import {useNavigate} from "react-router-dom";


function Index({data}: { data: ApplyResponse }) {
    let navigate = useNavigate();
    let visitorDetail = data.applyDetails[0];
    let approvalNodeResponses = data.approvalNodes;
    let hasComment = approvalNodeResponses.find(e => e.approvalPeoples.find(e2 => e2.comment));
    let open:boolean = false
    if(hasComment && data.status === 2) {
        open = true
    }
    return (
        <div className={styles.Main}>
            <div onClick={() => navigate("/main/recordDetail", {state: data.id})}>
                <ItemCenter label={<><Time style={{marginRight: '5px'}}/>申请时间</>}
                            value={moment(data.createTime).format('YYYY-MM-DD HH:mm:ss')}/>
                <Divider/>
                <ItemCenter label={'姓名'} value={visitorDetail.name}/>
                <ItemCenter label={'单位'} value={visitorDetail.company}/>
                <ItemCenter label={'事由'} value={data.reason}/>
            </div>
            <ItemProcess approvalNodes={data.approvalNodes} status={data.status} open={open}/>
        </div>
    );
}

export default Index;
