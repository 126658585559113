import {IBaseResponse} from "../common/DataType";
import request from "../util/request";


export const dictApi = {
    /**
     * 根据名字查询单条字典的值
     */
    getOneByName: (params: { name: string }) => {
        return request.get<IBaseResponse<string>>("/mapi/dict", {params});
    }
}
