import {ImageUploadItem} from "antd-mobile/es/components/image-uploader";

export type IBaseResponse<T> = {
    code: number //返回200，调用成功
    info: string //返回信息描述
    body: T //请求报文
}

export type PageQuery = {
    current: number //当前页
    size: number //每页大小
}

export type PageResponse<T> = {
    list: T[]
    total: number
    size: number
    current: number
    pages: number
}

export const emptyImageUploadItemPromise:Promise<ImageUploadItem> = new Promise((resolve, reject) => {
    setTimeout(() => {
    }, 0)
})

export const picSuffix: string[] = [".xbm", ".tif", ".pjp", ".svgz", ".jpg", ".jpeg", ".ico", ".tiff", ".gif", ".svg", ".jfif", ".webp", ".png", ".bmp", ".pjpeg", ".avif"]

