import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {useNavigate} from "react-router-dom";
import {dictApi} from "../../../api/dict";


function Index() {
    const navigate = useNavigate();
    const [data, setData] = useState<string>('')
    useEffect(() => {
        dictApi.getOneByName({name: 'popupContent'}).then(res => {
            setData(res.data.body)
        })
    }, [])
    return (
        <div className={styles.Main}>
            <div className={styles.First}>
                <div dangerouslySetInnerHTML={{ __html: data}}/>
                <div className={styles.Btn} onClick={() => navigate("/main/home", {replace: true})}>我 知 道 了</div>
            </div>
        </div>
    );
}

export default Index;
