import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {Form, ImageUploader, Input, Picker, Toast} from "antd-mobile";
import StrUtil from "../../../../util/StrUtil";
import RegexUtil from "../../../../util/RegexUtil";
import {VisitorSaveRequest} from "../../../../api/apply";
import {AddOne, ReduceOne} from "@icon-park/react";
import IUtil from "../../../../util/IUtil";
import {FormInstance} from "antd-mobile/es/components/form";
import {PickerColumnItem, PickerValue} from "antd-mobile/es/components/picker-view";
import VehiclePlateKeyboard from "../../../../common/VehiclePlateKeyboard";
import {detailEmptyData} from "../index";
// @ts-ignore
import lrz from 'lrz';
import {fileApi} from "../../../../api/file";
import {ImageUploadItem} from "antd-mobile/es/components/image-uploader";
import IdentityUtil from "../../../../util/IdentityUtil";
import {CloseCircleFill, TeamFill} from "antd-mobile-icons";
import {visitorRecordApi, VisitorRecordResponse} from "../../../../api/visitorRecord";
import ArrUtil from "../../../../util/ArrUtil";
import VisitorItem from "./VisitorItem";

const genderColumns = [
    [
        {label: '男', value: '男'},
        {label: '女', value: '女'},

    ],
]

const healthColumns = [
    [
        {label: '异常', value: '异常'},
        {label: '健康', value: '健康'},

    ],
]

const idTypeColumns = [
    [
        {label: '身份证', value: '身份证'},
        {label: '护照', value: '护照'},
        {label: '其它', value: '其它'},

    ],
]

type PropsType = {
    form: FormInstance
}

function Index({form}: PropsType) {
    const [showGenderSelect, setShowGenderSelect] = useState<boolean[]>([])
    const [showIdTypeSelect, setShowIdTypeSelect] = useState<boolean[]>([])
    const [showHealthSelect, setShowHealthSelect] = useState<boolean[]>([])
    const [showVisitor, setShowVisitor] = useState<boolean[]>([])
    const [showPlateKeyboard, setShowPlateKeyboard] = useState<boolean[]>([])
    const [visitorResponses, setVisitorResponse] = useState<VisitorRecordResponse[]>([])
    useEffect(() => {
        getVisitorResponse()
    }, [])

    const getVisitorResponse = () => {
        visitorRecordApi.list().then(res => {
            setVisitorResponse(res.data.body)
        })
    }

    const checkPhone = (_: any, value: string) => {
        if (StrUtil.isEmpty(value)) {
            return Promise.reject(new Error('手机号不能为空'))
        }
        if (!RegexUtil.checkPhone(value)) {
            return Promise.reject(new Error('手机号格式错误'))
        }
        return Promise.resolve()
    }

    const checkIdentityCardNumber = (_: any, value: string) => {
        if (StrUtil.isEmpty(value) || !IdentityUtil.isValid(value)) {
            return Promise.reject(new Error('身份证格式错误'))
        }
        return Promise.resolve()

    }
    const uploadFile = async (file: File) => {
        let formData = new FormData();
        formData.append("file", file);
        let axiosResponse = await fileApi.uploadFile(formData);
        let fileUrl = axiosResponse.data.body;
        const imageUploadItem: ImageUploadItem = {
            url: fileUrl,
            key: fileUrl
        }
        return imageUploadItem
    }
    let visitorColumns = visitorResponses.map(e => {
        return {
            label: <VisitorItem data={e} refreshVisitorResponse={() => getVisitorResponse()}/>,
            value: e.identityCardNumber
        }
    })
    let data: VisitorSaveRequest = form.getFieldsValue()
    let len = 1
    if (data.details) {
        len = data.details.length
        let identityCardNumbers: string[] = data.details.map(e => e.identityCardNumber);
        visitorColumns = visitorColumns.filter(e => !identityCardNumbers.includes(e.value))
    }

    if (ArrUtil.isEmpty(visitorColumns)) {
        visitorColumns = []
    }
    return (
        <div className={styles.Main}>
            <Form.Array
                name='details'
                renderHeader={({index}, {add, remove}) => {
                    let addRightEl = undefined
                    if (index === len - 1) {
                        addRightEl =
                            <div className={styles.Right}
                                 onClick={() => {
                                     add(JSON.parse(JSON.stringify(detailEmptyData)))
                                     len++
                                 }}>
                                <AddOne theme="outline" size="18" fill="#409EFF"/>
                                <div className={styles.RightContent}>添加同行人</div>
                            </div>
                    }
                    let reduceEl = undefined
                    if (index != 0) {
                        reduceEl =
                            <ReduceOne theme="outline" size="18" fill="#333" onClick={() => {
                                remove(index)
                                len--
                            }}/>
                    }
                    return <div className={styles.ItemHeader}>
                        {reduceEl}
                        <p className={styles.Left}>人员{index + 1}</p>
                        {addRightEl}
                    </div>
                }
                }
            >
                {fields =>
                    fields.map(({index}) => {

                        const fillVisitor = (val: string) => {
                            let e = visitorResponses.find(e => e.identityCardNumber === val);
                            if (!e) {
                                return
                            }
                            let data: VisitorSaveRequest = form.getFieldsValue();
                            let detail = data.details[index];

                            detail.gender = e.gender
                            detail.name = e.name
                            detail.phone = e.phone
                            detail.company = e.company
                            detail.healthStatus = e.healthStatus
                            detail.identityCardNumber = e.identityCardNumber
                            detail.idType = e.idType
                            if (detail.idType) {
                                detail.idTypeItem = [detail.idType]
                            }
                            detail.plateNumber = e.plateNumber
                            if (detail.healthStatus) {
                                detail.healthStatusItem = [detail.healthStatus]
                            }
                            if (detail.gender) {
                                detail.genderItem = [detail.gender]
                            }
                            let gender = IUtil.calcGender(detail.identityCardNumber);
                            detail.genderItem = gender ? [gender] : []
                            form.setFieldsValue(data)
                        }

                        const calGender = () => {
                            let data: VisitorSaveRequest = form.getFieldsValue();
                            let detail = data.details[index];
                            let gender = IUtil.calcGender(detail.identityCardNumber);
                            detail.genderItem = gender ? [gender] : []
                            form.setFieldsValue(data)
                        }
                        const changeShowPlateKeyboard = (val: boolean) => {
                            showPlateKeyboard[index] = val
                            setShowPlateKeyboard([...showPlateKeyboard])
                        }
                        const clearPlateKeyboard = () => {
                            let data: VisitorSaveRequest = form.getFieldsValue();
                            let detail = data.details[index];
                            detail.plateNumber = ''
                            form.setFieldsValue(data)
                        }
                        const changeShowIdTypeSelect = (val: boolean) => {
                            showIdTypeSelect[index] = val
                            setShowIdTypeSelect([...showIdTypeSelect])
                        }
                        const changeShowGenderSelect = (val: boolean) => {
                            showGenderSelect[index] = val
                            setShowGenderSelect([...showGenderSelect])
                        }
                        const changeShowHealthSelect = (val: boolean) => {
                            showHealthSelect[index] = val
                            setShowHealthSelect([...showHealthSelect])
                        }
                        const changeShowVisitorSelect = (val: boolean) => {
                            showVisitor[index] = val
                            setShowVisitor([...showVisitor])
                        }
                        const genderValFormat = (item: (PickerColumnItem | null)[]) => {
                            return (item && item.length > 0) ? <p style={{}}>{item[0]?.value}</p> :
                                <p style={{color: '#cccccc'}}>请选择</p>
                        }
                        const healthValFormat = (item: (PickerColumnItem | null)[]) => {
                            return (item && item.length > 0) ? <p style={{}}>{item[0]?.value}</p> :
                                <p style={{color: '#cccccc'}}>请选择</p>
                        }
                        const plateNoValFormat = (item: (string | undefined)) => {
                            return item ? <p style={{}}>{item}</p> :
                                <p style={{color: '#cccccc'}}>请选择（严禁社会运营车辆入校）</p>
                        }
                        const checkFileIds = () => {
                            let data: VisitorSaveRequest = form.getFieldsValue();
                            let detail = data.details[index];
                            if (!detail.attachmentItem || detail.attachmentItem.length === 0) {
                                return Promise.reject(new Error('附件不能为空'))
                            }
                            return Promise.resolve()
                        }

                        const checkFile = (file: File) => {
                            if (file.size / 1024 / 1024 >= 7) {
                                Toast.show("上传图片不能大于7M")
                                return null
                            }
                            return file
                        }
                        let data: VisitorSaveRequest = form.getFieldsValue();
                        let identityCardNumberEl =
                            <Form.Item name={[index, 'identityCardNumber']} label='证件号'
                                       rules={[{required: true}]}>
                                <Input placeholder='请先选择证件类型' disabled/>
                            </Form.Item>
                        if (data && data.details) {
                            let detail = data.details[index];
                            if (detail.idTypeItem) {
                                if (detail.idTypeItem[0] === '身份证') {
                                    identityCardNumberEl =
                                        <Form.Item name={[index, 'identityCardNumber']} label='身份证'
                                                   rules={[{validator: checkIdentityCardNumber}]}
                                                   required>
                                            <Input placeholder='请输入身份证' onChange={calGender}
                                                   clearable/>
                                        </Form.Item>
                                } else if (detail.idTypeItem[0] === '护照') {
                                    identityCardNumberEl =
                                        <Form.Item name={[index, 'identityCardNumber']} label='护照'
                                                   rules={[{required: true}]}>
                                            <Input placeholder='请输入护照'
                                                   clearable/>
                                        </Form.Item>
                                } else if (detail.idTypeItem[0] === '其它') {
                                    identityCardNumberEl =
                                        <Form.Item name={[index, 'identityCardNumber']} label='证件号'
                                                   rules={[{required: true}]}>
                                            <Input placeholder='请输入证件号'
                                                   clearable/>
                                        </Form.Item>
                                }
                            }
                        }
                        return <>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Form.Item help={'点击右边图标可以选择以前申请过的访客'} name={[index, 'name']} label='姓名'
                                           rules={[{required: true}]}>
                                    <Input placeholder='请输入姓名' clearable/>
                                </Form.Item>
                                <div style={{width: 60, display: 'flex', justifyContent: 'center'}} onClick={() => {
                                    changeShowVisitorSelect(true)
                                }}>
                                    <TeamFill/>
                                </div>
                                <Picker
                                    style={{
                                        height: 300,
                                        '--item-height': '60px',
                                    }}
                                    popupStyle={{height: '600px'}}
                                    onClose={() => changeShowVisitorSelect(false)}
                                    columns={[visitorColumns]}
                                    visible={showVisitor[index]}
                                    onConfirm={(item: (PickerValue | null)[]) => {
                                        if (item) {
                                            fillVisitor(item[0] as string)
                                        }
                                    }}
                                />
                            </div>
                            <Form.Item name={[index, 'phone']} label='电话' rules={[{validator: checkPhone}]}
                                       required>
                                <Input placeholder='请输入联系电话' clearable/>
                            </Form.Item>
                            <Form.Item name={[index, 'idTypeItem']} label='证件类型' rules={[{required: true}]}
                                       trigger='onConfirm'
                                       onClick={() => changeShowIdTypeSelect(true)}>
                                <Picker
                                    onClose={() => changeShowIdTypeSelect(false)}
                                    columns={idTypeColumns}
                                    visible={showIdTypeSelect[index]}>
                                    {genderValFormat}
                                </Picker>
                            </Form.Item>
                            {identityCardNumberEl}
                            <Form.Item name={[index, 'genderItem']} label='性别' rules={[{required: true}]}
                                       trigger='onConfirm'
                                       onClick={() => changeShowGenderSelect(true)}>
                                <Picker
                                    onClose={() => changeShowGenderSelect(false)}
                                    columns={genderColumns}
                                    visible={showGenderSelect[index]}>
                                    {healthValFormat}
                                </Picker>
                            </Form.Item>
                            <Form.Item name={[index, 'company']} label='单位' rules={[{required: true}]}>
                                <Input placeholder='请输入访客所在单位' clearable/>
                            </Form.Item>
                            <Form.Item name={[index, 'healthStatusItem']} label='健康状态' rules={[{required: true}]}
                                       trigger='onConfirm'
                                       onClick={() => changeShowHealthSelect(true)}>
                                <Picker
                                    onClose={() => changeShowHealthSelect(false)}
                                    columns={healthColumns}
                                    visible={showHealthSelect[index]}>
                                    {genderValFormat}
                                </Picker>
                            </Form.Item>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Form.Item style={{width: '100%'}} name={[index, 'plateNumber']} label='车牌号'
                                           onClick={() => {
                                               IUtil.confirmDialog("严禁社会运营车辆入校", () => changeShowPlateKeyboard(true))
                                           }}
                                           trigger='onConfirm' arrow={false}>
                                    <VehiclePlateKeyboard visible={showPlateKeyboard[index]}
                                                          cancel={() => changeShowPlateKeyboard(false)}>
                                        {plateNoValFormat}
                                    </VehiclePlateKeyboard>
                                </Form.Item>
                                <div style={{width: 50, display: 'flex', justifyContent: 'center'}}
                                     onClick={clearPlateKeyboard}>
                                    <CloseCircleFill/>
                                </div>
                            </div>
                            <Form.Item name={[index, 'attachmentItem']} label={
                                <div className={styles.Attachment}>
                                    <div className={styles.P1}>
                                        <div>附件</div>
                                        <p>（最多6张）</p>
                                    </div>
                                    <div className={styles.P2}>根据学校相关规定上传相关材料</div>
                                </div>
                            }>
                                <ImageUploader
                                    maxCount={6}
                                    style={{'--cell-size': '60px'}}
                                    upload={uploadFile}
                                    beforeUpload={checkFile}

                                />
                            </Form.Item>
                        </>
                    })
                }
            </Form.Array>
        </div>
    );
}

export default Index;
