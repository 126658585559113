import React, {useEffect} from 'react';
import styles from './index.module.scss';
import {useNavigate, useSearchParams} from "react-router-dom";
import {DotLoading} from "antd-mobile";
import IUtil from "../../util/IUtil";

function Index() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {

        let env = searchParams.get('env');
        if (env && ['cas'].includes(env)) {
            IUtil.setEnv(env)
            navigate("/main/home", {replace: true})
        } else {
            alert('链接无效')
        }

    });

    return (
        <div className={styles.Main}>
            <DotLoading color='currentColor'/>
        </div>
    );
}

export default Index;
