import React, {useImperativeHandle, useState} from 'react';
import styles from './index.module.scss';
import LatticeContainer from "../LatticeContainer";
import LatticeKey from "../LatticeKey";

const keys: string[][] = [
    ['粤', '京', '冀', '沪', '津', '晋', '蒙', '辽', '吉', '黑', '苏', '浙', '皖', '闽', '赣', '鲁', '豫', '鄂', '湘', '桂', '琼', '渝', '川', '贵', '云', '藏', '陕', '甘', '青', '宁', '新'],
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '港', '澳', '学', '领', '警']
]

type PropsType = {
    onRef: any
}

function Index(props: PropsType) {
    const [currentIdx, setCurrentIdx] = useState<number>(0)
    const [plateNo, setPlateNo] = useState<string[]>(['', '', '', '', '', '', ''])
    const changeInput = (val: string) => {
        plateNo[currentIdx] = val
        const plateNOChange = [...plateNo]
        setPlateNo(plateNOChange)
        let newCurrentIdx = currentIdx + 1;
        if (newCurrentIdx < plateNo.length) {
            setCurrentIdx(newCurrentIdx)
        }
    }
    useImperativeHandle(props.onRef, () => ({
        deleteInput: () => {
            plateNo[currentIdx] = ''
            const plateNOChange = [...plateNo]
            setPlateNo(plateNOChange)
            let newCurrentIdx = currentIdx - 1;
            if (newCurrentIdx >= 0) {
                setCurrentIdx(newCurrentIdx)
            }
        },
        complete: () => {
            for (let item of plateNo) {
                if (item === '') {
                    return undefined;
                }
            }
            return plateNo.join("")
        }
    }));

    return (
        <div className={styles.Main}>
            <LatticeContainer plateNo={plateNo} idx={currentIdx} changeIdx={(idx) => setCurrentIdx(idx)}/>
            <div className={styles.Key}>
                {keys[currentIdx].map(((value, index) => {
                    return <LatticeKey key={index} value={value} onClick={() => changeInput(value)}/>
                }))}
            </div>
        </div>
    );
}

export default Index;
