import React from 'react';
import styles from './index.module.scss';

type PropsType = {
    value: string
    onClick: () => void
}

function Index(props: PropsType) {
    return (
        <div className={styles.Main} onClick={props.onClick}>
            {props.value}
        </div>
    );
}

export default Index;
