import React from 'react';
import styles from './index.module.scss';

type PropsType = {
    selected?: boolean
    value?: string
    onClick: () => void
}

function Index(props: PropsType) {
    if (props.selected) {
        return (
            <div className={styles.Selected} onClick={props.onClick}>
                {props.value}
            </div>
        )
    }
    return (
        <div className={styles.UnSelected} onClick={props.onClick}>
            {props.value}
        </div>
    );
}

export default Index;
